.auth-layout {
  height: 100vh;
  padding: var(--spacing-padding) calc(var(--spacing-padding) * 2);
  position: relative;
  overflow: hidden;

  &::before {
    width: 1365px;
    height: 733px;
    content: '';
    display: block;
    position: absolute;
    background-image: url(./../../images/auth/shape.png);
    left: calc((var(--spacing-padding) * 2) + 540px);
    top: 0;
  }

  > .main-header {
    > .logotipo {
      margin: 0;

      > a {
        > svg {
          height: 42px;
          fill: var(--primary-color);
        }
      }
    }
  }

  > .main-content {
    position: relative;
    transform: translateY(-50%);
    margin-top: -42px;
    top: 50%;

    > .auth-container {
      max-width: 440px;

      > .panel {
        > .title {
          margin-bottom: 40px;
        }
      }
    }
  }
}
