@font-face {
  font-family: 'Evo';
  src: local('Evo'), url(./../../fonts/evo/Evo-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Evo';
  src: local('Evo'), url(./../../fonts/evo/Evo-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Evo';
  src: local('Evo'), url(./../../fonts/evo/Evo-Semibold.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Evo';
  src: local('Evo'), url(./../../fonts/evo/Evo-Bold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Evo';
  src: local('Evo'), url(./../../fonts/evo/Evo-Black.ttf) format('truetype');
  font-weight: 700;
}
