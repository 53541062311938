.card {
  border: none;
  box-shadow: var(--box-shadow);

  .card-header {
    padding: 12px 16px;
    background-color: #fff;
    border-color: var(--border-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    &.has-tabs {
      background-color: #e5e5f2;
      border-color: #eee;

      > .nav-tabs {
        border: 0;

        > .nav-item {
          > a.nav-link {
            margin-top: 0;
            border-color: transparent;
            cursor: pointer;

            &.active {
              font-weight: 500;
            }
          }
        }

        > button {
          margin: 0;
          border-radius: 0;
          border: 0;
          border-right: 1px solid #eee;

          &:first-child {
            border-radius: var(--border-radius) 0 0 0;
          }

          &:last-child {
            border-right: 0;
            border-radius: 0 var(--border-radius) 0 0;
          }

          &.active {
            color: var(--primary-color);
            background-color: #fff;
          }
        }
      }
    }
  }

  .card-body {
    padding: 12px 16px;
  }

  .card-footer {
    padding: 12px 16px;
    background-color: #fff;
    border-color: var(--border-color);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .card-color {
    background-color: #dee2e6 !important;
    padding: 0px 0px;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}
