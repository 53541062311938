h1 {
  font-weight: 400;
  font-size: 2.714rem;
}

h2 {
  font-weight: 600;
  font-size: 2.8rem;
}

h3 {
  font-weight: 600;
  font-size: 1.8rem;
}

h4 {
  font-weight: 600;
  font-size: 1.6rem;
}

h5 {
  font-weight: 600;
  font-size: 1.25rem;
}

h6 {
  font-weight: 600;
  font-size: 1.1rem;
}

.text-upper {
  text-transform: uppercase;
}

.text-nowrap {
  white-space: nowrap;
}
