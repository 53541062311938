.react-select-outline {
  > .react-select__control {
    border-width: 2px;
    border-color: #fff !important;
    background-color: transparent !important;

    > .react-select__value-container {
      > .react-select__placeholder,
      > .react-select__single-value {
        color: #fff;
        font-size: 1rem;
      }
    }
  }
}
